//input chip
@import "node_modules/@pathofdev/react-tag-input/src/styles/index.scss";

// custom variables
// spacing
:root {
    --base-spacing:             1rem;
    --base-font-size:           1rem;
    --body-font-weight:         400;
    --body-font-size:           calc(.8125 * var(--base-font-size));
    --bs-font-sans-serif:       'IDXSans Regular', 'IDXSerif Regular';
    --font-weight-semi-bold:    500;
    --label-font-size:          var(--body-font-size);
    --label-font-weight:        var(--body-font-weight);
}

//dropdown


//side navbar
$sidenav-item-spacing:          0.65rem 0.75rem;
$sidenav-item-height:           2.875rem;
$sidenav-subitem-spacing:       0.8125rem 1.5rem 0.8125rem 2.30rem;

//card
$card-header-weight:            600;
$card-body-spacer-x:            0; 

//metrices
$metrices-weight:               600;

//form
$form-control-padding:          0.55rem 0.75rem;

//dropdown menu
$select-menu-item-spacing:      0.25rem 0.75rem;
$table-dropdown-paddng:         .35rem .75rem;

//tooltip
$tooltip-padding:               0.5rem 0.75rem;
$tooltip-fontsize:              calc(.75 * var(--base-font-size));

//New IDX color
$new-primary-color:             #FF006F;

//chart color
$chart-color-one: #FF006F;
$chart-color-two: #B30049;
$chart-color-three: #A7AE00;
$chart-color-four: #0CAE86;
$chart-color-five: #11FFCC;
$chart-color-six: #747700;
$chart-color-seven: #7C0032;
$chart-color-eight: #EEFF00;

